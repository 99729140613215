import React, { PureComponent } from 'react';
import axios from 'axios';
import { useTranslation, withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
// import google from 'googleapis';
import ReactLoading from "react-loading";
import Header from './Header.js'
import Footer from './Footer.js'
import BoxSearch from './BoxSearch.js'
import Menu from './Menu.js';
import LoginDialog from './LoginDialog.js';
import CopyrightPage from '../pages/CopyrightPage/CopyrightPage.js';

import HomePage from '../pages/HomePage/HomePage.js'
import LoginPage from '../pages/LoginPage/LoginPage.js'
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage.js'
import VipPage from '../pages/VipPage.js'
import NotFoundPage from '../pages/NotFoundPage.js'
import RecordingPage from '../pages/RecordingPage/RecordingPage.js'
import LiveroomPage from '../pages/LiveroomPage/LiveroomPage.js'
import PostPage from '../pages/PostPage/PostPage.js';
import UserPage from '../pages/UserPage/UserPage.js'
import TopUsersPage from '../pages/TopUsersPage/TopUsersPage.js'
import SearchPage from '../pages/SearchPage.js'
import BuyICoinPage from '../pages/BuyICoinPage/BuyICoinPage.js'
import TopRecordingsPage from '../pages/TopRecordingsPage/TopRecordingsPage.js';
import TopDuetRecordingsPage from '../pages/TopDuetRecordingsPage/TopDuetRecordingPage.js';
import PromotionManagerPage from '../pages/PromotionManagerPage/PromotionManagerPage.js';
import BannerManagerPage from '../pages/BannerManagerPage/BannerManagerPage.js';
import PackageManagerPage from '../pages/PackageManagerPage/PackageManagerPage.js';
import AccountManagerPage from '../pages/AccountManagerPage/AccountManagerPage.js';
import ProfilePage from '../pages/ProfilePage/ProfilePage.js';
import TransactionManagerPage from '../pages/RechargeManagerPage/TransactionManagerPage.js';
import MyGiftsPage from '../pages/MyGiftsPage/MyGiftsPage.js';
import UploadBeatPage from '../pages/UploadBeatPage/UploadBeatPage.js';
import MyImagesPage from '../pages/MyImagesPage/MyImagesPage.js';
import MyRecordingPage from '../pages/MyRecordingsPage/MyRecordingsPage.js';
import GiftManagerPage from '../pages/GiftManagerPage/GiftManagerPage';
import UpdateCupPage from '../pages/UpdateCupPage/UpdateCupPage.js';
import MixRecordingPage from '../pages/MixRecordingPage/MixedRecordingPage.js';
import TermPage from '../pages/TermPage.js';
import BeatManagerPage from '../pages/BeatManagerPage/BeatManagerPage.js';
import NotifyPage from '../pages/NotifyPage/NotifyPage.js';
import NotifyManagement from '../pages/NotifyManagement/NotifyManagement.js';
import AddIconForEventPage from '../pages/AddIconForEventPage/AddIconForEventPage.js';
import YoutubeSong from '../pages/Category/YoutubeSong.js';
import CompetitionPage from '../pages/CompetitionPage/CompetitionPage.js';

import RankItemPage from '../pages/RankItemPage/RankItemPage.js';
import VipPrivilege from '../pages/VipPrivilegePage/VipPrivilege.js';
import TestPage from '../pages/TestPage/TestPage.js';
import AvatarFrame from '../pages/ItemShopPage/AvatarFrame/AvatarFrame.js';
import CommentFrame from '../pages/ItemShopPage/CommentFrame/CommentFrame.js';
import RoomEntryEffect from '../pages/ItemShopPage/RoomEntryEffect/RoomEntryEffect.js';
import PersonalCard from '../pages/ItemShopPage/PersonalCard/PersonalCard.js';
import UserDecoration from '../pages/ItemShopPage/UserDecoration/UserDecoration.js';
import RecordingVipUsers from '../pages/RecordingVipUsers/RecordingVipUsers.js';
import SpecialGiftDiscountPage from '../pages/SpecialGiftDiscount/SpecialGiftDiscountPage.js'
import BOTFollowing from '../pages/BOTManagerPage/BOTFollowing.js';
import SmartLookManager from '../pages/SmartLookManager/SmartLookManager.js';



import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    withRouter
} from "react-router-dom";

import ScrollToTop from './ScrollToTop.js';
import MyContextProvider from './MyContextProvider';
import { FacebookProvider } from 'react-facebook';

import { setCookie, deleteAllCookies } from "../common/cookie.js";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import DownloadPage from '../pages/DownloadPage/DownloadPage.js';
import SupportUser from '../pages/ChatForAdminPage/SupportUser.js';
import SubscribeConsoleLogPage from '../pages/SubscribeConsoleLogPage/SubscribeConsoloLogPage.js';
import OkaraConsoleLogPage from '../pages/OkaraConsoleLogPage/OkaraConsoleLogPage.js';
import LuckyGiftManagerPage from '../pages/LuckyManagerPage/LuckyGiftManagerPage.js';
import PostFanpagePage from '../pages/PostFanpagePage/PostFanpagePage.js';
import RankGiftPage from '../pages/RankGiftPage/RankGiftPage.js';
import MinigameManagerPage from '../pages/MinigameManagerPage/MinigameManagerPage.js';
import PushNotificationPage from '../pages/PushNotificationPage/PushNotificationPage.js';
import {detectMob, detectApp} from '../common/utils.js';
import SubscribeOkaraDialog from './SubscribeOkaraDialog.js';
import ConfirmSubscribeOkaraDialog from './ConfirmSubscribeOkaraDialog.js';
import UnsubscribeOkaraDialog from './UnsubscribeOkaraDialog.js';
import EvenLevelPrivilege from '../pages/EvenLevelPrivilege/EvenLevelPrivilegePage.js';

import '../assets/css/bootstrap.min.css';
import '../assets/css/css.css';
import '../assets/css/comment.css';
import '../assets/css/player.css';
import "../assets/css/style.css";
import "../assets/css/header.css";
import '../assets/css/toprecordhome.css';

import $ from 'jquery';
import PhoneVetificationDialog from './PhoneVetificationDialog.js';
import ConfirmPhoneVetificationDialog from './ConfirmPhoneVetificationDialog.js';
import RegisterPhoneDialog from './RegisterPhoneDialog.js';
import GenerateLinkPage from '../pages/GeneateLinkPage/GenerateLinkPage.js';
import SingButton from './SingButton.js';
import BannerFullPageManager from './../pages/BannerFullManagerPage/BannerFullPageManager';
import TopVipUserOfThisMonth from '../pages/TopVipUserOfThisMonth/TopVipUserOfThisMonth.js';
import SetPrivilegeAdmin from './../pages/SetPrivilegeAdminPage/SetPrivilegeAdmin';
import LuckyGiftManager from '../pages/LuckyGiftGaveManager/LuckyGiftGaveManager.js';
import LevelPrivilege from '../pages/LevelPrivilegePage/LevelPrivilege.js';
import DecorationItemType from '../pages/DecorationItemTypePage/DecorationItemType.js';
import CouplePage from './../pages/CouplePage/CouplePage';
import AdminPage from './AdminPage.js';
import ErrorPage from '../pages/ErrorPage.js';
import MedalManagerPage from '../pages/MedalManagerPage/MedalManagerPage.js';
import DeepARFilterPage from '../pages/DeepARFilterPage/DeepARFilterPage.js';
import DeepAREffectPage from '../pages/DeepAREffectPage/DeepAREffectPage.js';
import DeletedGiftPage from '../pages/GiftManagerPage/DeletedGiftPage.js';
import BirthdayGiftManager from '../pages/BirthdayGiftPage/BirthdayGiftManager.js';
import SingEffect from '../pages/ItemShopPage/SingEffect/SingEffect.js';
import SurveyPage from '../pages/SurveyPage/SurveyPage.js';
import ScreenManagerPage from '../pages/ScreenManagerPage/ScreenManagerPage.js';
import AppPolicyPage from '../pages/AppPolicyPage/AppPolicyPage.js';
import SendCupHistoryPage from '../pages/SendCupHistoryPage/SendCupHistoryPage.js';
import GameManagerPage from '../pages/GameManagerPage/GameManagerPage.js';
import CreateAndUpdateGameTypePage from '../pages/GameManagerPage/ChildrenPage/CreateAndUpdateGameTypePage/CreateAndUpdateGameTypePage.js';
import CreateAndUpdateGameLevelPage from '../pages/GameManagerPage/ChildrenPage/CreateAndUpdateGameLevelPage/CreateAndUpdateGameLevelPage.js';
import RecordingVipPage from '../pages/RecordingVipPage/RecordingVipPage.js';
import UserSpecialGiftPage from '../pages/UserSpecialGiftPage/UserSpecialGiftPage.js';
import AddRecordingVipPage from '../pages/RecordingVipPage/ChildrenPage/AddRecordingVipPage/AddRecordingVipPage.js';
import DetailRecordingVipPage from '../pages/RecordingVipPage/ChildrenPage/DetailRecordingVipPage/DetailRecordingVipPage.js';
import TheVoiceExamnerPage from '../pages/TheVoiceExamnerPage/TheVoiceExamnerPage.js'; 
import Item3DPage from '../pages/Item3DPage/Item3DPage.js'; 
import SellerPage from '../pages/SellerPage/SellerPage.js'; 
import ManagerSellerPage from '../pages/ManagerSellerPage/ManagerSellerPage.js';
import LivestreamPage from '../pages/LivestreamPage/LivestreamPage.js'; 
import ContentEventPage from '../pages/ContentEventPage/ContentEventPage.js'; 
import FeatureRequestPage from '../pages/FeatureRequestPage/FeatureRequestPage.js'; 
import AddYoutubeMidiPage from '../pages/YoutubeMidiPage/ChildrenPage/AddYoutubeMidiPage/AddYoutubeMidiPage.js';
import DetailYoutubeMidiPage from '../pages/YoutubeMidiPage/ChildrenPage/DetailYoutubeMidiPage/DetailYoutubeMidiPage.js';
import YoutubeMidiPage from '../pages/YoutubeMidiPage/YoutubeMidiPage.js';
import UserPostPage from '../pages/UserPostPage/UserPostPage.js';
import HashtagPage from '../pages/HashtagPage/HashtagPage.js';
import RealGiftPage from '../pages/RealGiftPage/RealGiftPage.js';
import BannerSellMicPage from '../pages/BannerSellMicPage/BannerSellMicPage.js';
import PromoBannerSellMicPage from '../pages/PromoBannerSellMicPage/PromoBannerSellMicPage.js';
import RecheckApplePage from '../pages/RecheckApplePage/RecheckApplePage.js';
import GameBettingHistoryManagerPage from '../pages/GameBettingHistoryManagerPage/GameBettingHistoryManagerPage.js';
import WinRateFishHunterPage from '../pages/FishHunterManager/WinRateFishHunterPage.js';
import LinkedAccountPage from '../pages/LinkedAccountPage/LinkedAccountPage.js';
import GiftLevelManagerPage from '../pages/GiftLevelManagePage/GiftLevelManagerPage.js';
import SellerId from '../pages/ManagerSellerPage/components/SellerId.js';
import ManagerNewDevice from '../pages/ManagerNewDevice/components/ManagerNewDevice.js';
import ManagerHotMv from '../pages/ManagerHotMv/ManagerHotMv.js';
import SupportNewUser from '../pages/ChatForAdminPage/SupportNewUser.js';
import ManagerDeadAccount from '../pages/ManagerDeadAccount/ManagerDeadAccount.js';
import ListRecordingOfAccount from '../pages/ManagerDeadAccount/components/ListRecordingOfAccount.js';
import BOTManagerPage from '../pages/BOTManagerPage/BOTManagerPage.js';
import BOTInteractManagerPage from '../pages/BOTInteractManagerPage/BOTInteractManagerPage.js';
import TopBeatPage from '../pages/TopBeatManagerPage/TopBeatPage.js';
import ListChatSupportUser from '../pages/ChatForAdminPage/ListChatSupportUser.js';
import IdolRecording from '../pages/IdolRecording/IdolRecording.js'
import BotRecording from '../pages/BotRecording/BotRecordingPage.js'
import IdolManagerPage from '../pages/IdolManagerPage/IdolManagerPage.js';
import AvatarChangePage from '../pages/AccountManagerPage/components/AvatarChangePage.js';
import DailyTaskPage from '../pages/DailyTaskManagerPage/DailyTaskPage.js';
import MidiRecordingPage from '../pages/MidiRecordingManager/MidiRecordingPage.js';
import MidiRecordingDetail from '../pages/MidiRecordingManager/component/MidiRecordingDetail.js';
import ClientLogPage from '../pages/ClientLogPage/ClientLogPage.js';
import ClientLogDetailPage from '../pages/ClientLogPage/ClientLogDetailPage.js';
import TopVideoRecordingPage from '../pages/TopVideoRecordingsPage/TopVideoRecordingPage.js';
import SurveyNPSPage from '../pages/SurveyNPS/SurveyNPSPage.js';
import DiceGameInfoHistory from '../pages/DiceGameInfoHistory/DiceGameInfoHistory.js';
import VirtualBackgroundPage from '../pages/VirtualBackground/VirtualBackgroundPage.js';
import LiveRoomInfo from '../pages/LiveRoomInfo/LiveRoomInfo.js';
import PartnerManagerPage from '../pages/PartnerPage/PartnerManagerPage.js'
import PartnerIcoinDetail from '../pages/PartnerPage/PartnerIcoinDetail.js'

toast.configure();
var site = null;
var wait = true;
class App extends PureComponent {
    constructor(props) {
        super(props);
        this.checkParamLogin();
        //this.checkParamLogin = this.checkParamLogin.bind(this);
        axios.defaults.withCredentials = true;

        this.updateUser = this.updateUser.bind(this);
        this.reLoginUser = this.reLoginUser.bind(this);
        this.updateUserTemp = this.updateUserTemp.bind(this);
        this.gotoPage = this.gotoPage.bind(this);
        // let user = {
        //  name: "abc",
        //  profileImageLink: "https://data4.ikara.co/data/minio/ikara-data/avatars/513222759149779/513222759149779_200.jpg",
        //  isAdmin: true
        // }

        // let user = {
        //  facebookId: "105912617350916906040",
        //  uid: 6868,
        //  name: "abc",
        //  profileImageLink: "https://data4.ikara.co/data/minio/ikara-data/avatars/513222759149779/513222759149779_200.jpg",
        //  isAdmin: true,
        //  role: "MANAGER"
        // }

        // ($env:HTTPS = "true") -and (npm start)
        this.state = {
            user: null,
            userTemp: null,
            updateUser: this.updateUser,
            updateUserTemp: this.updateUserTemp,
            reLoginUser: this.reLoginUser,
            isAdmin: true,
            registerVipType: "DAY",
            role: null,
            isActive:  false,
            isLoading: true,
            isManager: false
        }

        this.redirect();

    }

updateRegisterVipType(type) {
        this.setState({
            registerVipType: type
        })
    }

    redirect = () => {
        let protocol = window.location.protocol;
        let hostName = window.location.host;
        let port = window.location.port;
        let pathName = window.location.pathname;
        let domains = hostName.split(".");

        // if (hostName.includes("okara.tls")) {
        //  return;
        // }

        if ((domains.length < 3 || protocol == "http:") && !hostName.includes("localhost")) {
            if (domains.length < 3) {
                hostName = "www." + hostName;
            }

            if (protocol == "http:") {
                protocol = "https:"
            }

            let url = protocol + "//" + hostName + pathName;
            window.location.replace(url);
        }
    }


    updateUser(user) {
        this.setState(
            {
                user: user
            }
        )
    }

    updateUserTemp(user) {
        this.setState(
            {
                userTemp: user
            }
        )
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ isLoading: false });
        }, 1200);

        this.reLoginUser();
    }

    componentDidUpdate() {
        console.log("component did update");
    }

    reLoginUser() {
        const json = JSON.stringify({ domain: window.location.hostname });

        axios.post(global.config.apiDomain + '/rest/loginuser', json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            const { user, role } = res.data;

            if (user != null) {
                if(role != null){
                    user.role = role;
                }
                // if(user.uid == 4175719 || user.uid == 4370682 ) {
                //  alert(navigator.platform);
                //  alert(navigator.userAgent);
                //  alert(detectMob())
                // }

                this.setState({
                    user: user,
                });

                window.localStorage.setItem("login", true);
                window.localStorage.setItem("userId", user.facebookId);
                window.localStorage.setItem("profileImageLink", user.profileImageLink);
                window.localStorage.setItem("name", user.name);
                window.localStorage.setItem("uid", user.uid);

                setCookie("login", "true", 1);
                setCookie("userId", user.facebookId, 1);
                setCookie("profileImageLink", user.profileImageLink, 1);
                setCookie("name", user.name, 1);
            } else {
                window.localStorage.removeItem("login");
                window.localStorage.removeItem("userId");
                window.localStorage.removeItem("profileImageLink");
                window.localStorage.removeItem("name");

                deleteAllCookies();

                this.setState({
                    isAdmin: false
                })
            }
        }, err => {
            window.localStorage.removeItem("login");
            window.localStorage.removeItem("userId");

            this.setState({
                isAdmin: false
            })
        });
    }
      
    checkParamLogin() {
        // if (window.localStorage.getItem("login") == "true"){
        //  console.log("Bạn đã login từ trước!");
        //  return;
        // }
        let self = this;

        const queryParams = new URLSearchParams(window.location.search);
        const parameters = queryParams.get('parameters');
        if (parameters == null) {   
            wait = false;           
            return;
        }
        var base54Code = parameters.split("-");
        if (base54Code == null || base54Code.length == 0 || base54Code[0] == null) {    
            wait = false;               
            return;
        }
        var base = Buffer.from(base54Code[0], 'base64');
        var paramJSON = null;
        try {
            paramJSON = JSON.parse(base.toString());
        } catch (error) {
            console.log(error);
            wait = false;           
            return;
        }
        var deviceID = paramJSON.userId;
        var method = paramJSON.method;  
        site =  paramJSON.site; 
        if (deviceID == null || method != "loginDeviceID") {    
            wait = false;               
            return;
        }
            axios.post(global.config.apiDomain + '/rest/loginuser/loginDeviceID', parameters, {
                headers: {
                  'Content-Type': 'application/json'
                }
              }).then(res => {
                console.log("login")
            
                const { user } = res.data;
                if (user != null) {
                  window.localStorage.setItem("login", true);
                  window.localStorage.setItem("userId", user.facebookId);
                  window.localStorage.setItem("profileImageLink", user.profileImageLink);
                  window.localStorage.setItem("name", user.name);
                  setCookie("login", "true", 1);
                  setCookie("userId", user.facebookId, 1);
                  setCookie("profileImageLink", user.profileImageLink, 1);
                  setCookie("name", user.name, 1);
                  console.log(user)
                  this.setState({
                    user: user,
                });

                }
                if (site != null && site != ""){
                    this.gotoPage();
                }else { wait = false;} 

                console.log("ENDlogin")

              })
    }

    gotoPage(){
        window.location.replace(site);
        window.location.href = site;
    }

    render() {
       
        const { t } = this.props;
        let isLogin = window.localStorage.getItem("login");
        var { isAdmin, isActive, isLoading, isManager } = this.state;
        if (wait || isLoading)  return (
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
                <ReactLoading type="bubbles" color="#56aaff" height={200} width={100} />
            </div>
        );
        const { user } = this.state;
        if (user != null) {
            isAdmin = user.isAdmin;
            if(user.role == "MANAGER" || user.role == "ICOIN-MANAGER" || user.role == "CUSTOMER-SUPPORTER" || user.role == "SELLER" || user.role == "SINGER" || user.role == "TESTER" || user.role != "COLLABORATOR"){
                isActive = true;
            }
            if(user.role == "MANAGER" || user.role == "ICOIN-MANAGER" || user.role == "CUSTOMER-SUPPORTER"){
                isManager = true;
            }
        }

        return (
            <MyContextProvider value={this.state}>
                {/* <FacebookProvider 
                    appId={t("commons.facebook_app_id", "370514506352628")}
                    isMobile={false}
                    > */}
                <Router>
                    <ScrollToTop>
                        <Header></Header>
                        <BoxSearch></BoxSearch>
                        <Menu t={t}></Menu>
                        <Switch>
                            <Route exact path="/" component={HomePage}></Route>
                            <Route path="/recording/:recordingId" component={RecordingPage}></Route>
                            <Route path="/admin/seller-manager/sellerId/:sellerId" component={SellerId}></Route>
                            <Route path="/react/recording/:recordingId" component={RecordingPage}></Route>
                            {/* <Route path="/liveroom/:liveroomId" component={LiveroomPage}></Route> */}
                            <Route path="/post/:postId" component={PostPage}></Route>
                            {/* <Route path="/react/liveroom/:liveroomId" component={LiveroomPage}></Route> */}
                            <Route path="/user/:userId" component={UserPage}></Route>
                            <Route path="/top-users" component={TopUsersPage}></Route>
                            <Route path="/login">
                                {user ? <Redirect to="/" /> : <LoginPage t={t} />}
                            </Route>
                            <Route path="/my-images" >
                                {isLogin ? <MyImagesPage /> : <Redirect to="/login" />}
                            </Route>
                            <Route path="/my-recordings">
                                {isLogin ? <MyRecordingPage /> : <Redirect to="/login" />}
                            </Route>
                            <Route path="/my-gifts" >
                                {isLogin ? <MyGiftsPage /> : <Redirect to="/login" />}
                            </Route>
                            <Route path="/me">
                                {isLogin ? <ProfilePage /> : <Redirect to="/login" />}
                            </Route>
                            <Route path="/upload-beat">
                                {(isLogin && detectApp("vi")) ? <UploadBeatPage /> : <Redirect to="/login" />}
                            </Route>

                            <Route path="/beat-manager">
                                {(isLogin && detectApp("vi")) ? <BeatManagerPage /> : <Redirect to="/login" />}
                            </Route>
                            
                            <Route path="/linked-account">
                                {(isLogin && detectApp("en.yokara")) ? <LinkedAccountPage /> : <Redirect to="/login" />}
                            </Route>

                            <Route path="/buy-icoin">
                                {isLogin ? <BuyICoinPage /> : <Redirect to="/login" />}
                            </Route>

                            <Route path="/download" component={DownloadPage}></Route>
                            <Route path="/d" component={DownloadPage}></Route>
                            <Route path="/comeback" component={DownloadPage}></Route>
                            <Route path="/mix-recording-console" component={MixRecordingPage}></Route>
                            <Route path="/subscribe-console" component={SubscribeConsoleLogPage}></Route>
                            <Route path="/okara-console" component={OkaraConsoleLogPage}></Route>
                            <Route path="/privacy-policy" component={PrivacyPolicyPage}></Route>
                            <Route path="/term" component={TermPage}></Route>
                            {/*  */}
                            <Route path="/react/privacy-policy" component={PrivacyPolicyPage}></Route>
                            <Route path="/react/term" component={TermPage}></Route>
                            {/*  */}
                            {/* <Route path="/evenlevel" component={EvenLevelPrivilege}></Route> */}
                            <Route path="/vip" component={VipPage}></Route>
                            <Route path="/top-recordings" component={TopRecordingsPage}></Route>
                            <Route path="/top-duet-recordings" component={TopDuetRecordingsPage}></Route>
                            <Route path="/search/:keyword" component={SearchPage}></Route>

                            <Route path="/error500" component={ErrorPage}></Route>
                                        {isActive && <Route path="/admin/evenlevel" component={EvenLevelPrivilege}></Route>}
                            {isActive && <Route path="/admin/promotion-manager" component={PromotionManagerPage}></Route>}
                            {isActive && <Route path="/admin/copyright" component={CopyrightPage}></Route>}
                            {isActive && <Route path="/admin/banner-manager" component={BannerManagerPage}></Route>}
                            {isActive && <Route path="/admin/package-manager" component={PackageManagerPage}></Route>}
                            {isActive && <Route path="/admin/recharge-manager" component={TransactionManagerPage}></Route>}
                            {isActive && <Route path="/admin/support" component={SupportUser}></Route>}
                            {isActive && <Route path="/admin/support-new/:id" component={SupportNewUser}></Route>}
                            {isActive && <Route path="/admin/idol-recording/:id" component={IdolRecording}></Route>}
                            {isActive && <Route path="/admin/bot-recording/:id" component={BotRecording}></Route>}
                            {isActive && <Route path="/admin/gift-manager" component={GiftManagerPage}></Route>}
                            {/* {isActive && <Route path="/admin/cup-manager" component={UpdateCupPage}></Route>} */}
                            {isActive && <Route path="/admin/icon-event-manager" component={AddIconForEventPage}></Route>}
                            {isActive && <Route path="/admin/special-gift" component={SpecialGiftDiscountPage}></Route>}
                            {isActive && <Route path="/admin/rank-gift" component={RankGiftPage}></Route>}
                            {isManager && <Route path="/admin/push-notification" component={NotifyPage}></Route>}
                            {isActive && <Route path="/admin/push-notify" component={PushNotificationPage}></Route>}
                            {isActive && <Route path="/admin/lucky-money-manager" component={LuckyGiftManagerPage}></Route>}
                            {isActive && <Route path="/admin/post-fanpage" component={PostFanpagePage}></Route>}
                            {isActive && <Route path="/admin/minigame-manager" component={MinigameManagerPage}></Route>}
                            {isActive && <Route path="/admin/generateLink" component={GenerateLinkPage}></Route>}
                            {isActive && <Route path="/notify-management" component={NotifyManagement}></Route>}
                            {isActive && <Route path="/admin/itemShop-manager" component={AvatarFrame}></Route>}
                            {isActive && <Route path="/admin/itemShopCommentFrame-manager" component={CommentFrame}></Route>}
                            {isActive && <Route path="/admin/itemShopRoomEntryEffect-manager" component={RoomEntryEffect}></Route>}
                            {isActive && <Route path="/admin/itemShop-user-decor-manager" component={UserDecoration}></Route>}
                            {isActive && <Route path="/admin/personal-card" component={PersonalCard}></Route>}
                            {isActive && <Route path="/admin/sing-effect" component={SingEffect}></Route>}
                            {isActive && <Route path="/admin/rank-items" component={RankItemPage}></Route>}
                            {isActive && <Route path="/admin/vip-privileges" component={VipPrivilege}></Route>}
                            {isActive && <Route path="/admin/banner-full" component={BannerFullPageManager}></Route>}
                            {isActive && <Route path="/admin/top-vip" component={TopVipUserOfThisMonth}></Route>}
                            {isActive && <Route path="/admin/test" component={TestPage}></Route>}
                            {isActive && <Route path="/angular/logcat.html"></Route>}
                            {isActive && <Route path="/admin/set-privilege-admin" component={SetPrivilegeAdmin}></Route>}
                            {isActive && <Route path="/admin/lucky-gift-manager" component={LuckyGiftManager}></Route>}
                            {isActive && <Route path="/admin/level-privilege" component={LevelPrivilege}></Route>}
                            {isActive && <Route path="/admin/couple" component={CouplePage}></Route>}
                            {isActive && <Route path="/admin/decoration-item-types" component={DecorationItemType}></Route>}
                            {isActive && <Route path="/admin/medal-manager" component={MedalManagerPage}></Route>}
                            {isActive && <Route path="/admin/deep-ar-filter" component={DeepARFilterPage}></Route>}
                            {isActive && <Route path="/admin/deep-ar-effect" component={DeepAREffectPage}></Route>}
                            {isActive && <Route path="/admin/gift-deleted" component={DeletedGiftPage}></Route>}
                            {isActive && <Route path="/admin/birthday-gift" component={BirthdayGiftManager}></Route>}
                            {isActive && <Route path="/admin/screen-manager" component={ScreenManagerPage}></Route>}
                            {isActive && <Route path="/admin/app-policy" component={AppPolicyPage}></Route>}
                            {isActive && <Route path="/admin/survey" component={SurveyPage}></Route>}
                            {isActive && <Route path="/admin/cup-history" component={SendCupHistoryPage}></Route>}
                            {isActive && <Route path="/admin/account-manager" component={AccountManagerPage}></Route>}
                            {isActive && <Route path="/admin/game-manager/create-game-type" component={CreateAndUpdateGameTypePage}></Route>}
                            {isActive && <Route path="/admin/game-manager/create-game-level" component={CreateAndUpdateGameLevelPage}></Route>}
                            {isActive && <Route path="/admin/game-manager/level/:gameLevelId" component={CreateAndUpdateGameLevelPage}></Route>}
                            {isActive && <Route path="/admin/game-manager/type/:gameTypeId" component={CreateAndUpdateGameTypePage}></Route>}
                            {isActive && <Route path="/admin/game-manager" component={GameManagerPage}></Route>}
                            {true && <Route path="/admin/recording-ai/add" component={AddRecordingVipPage}></Route>}
                            {true && <Route path="/admin/recording-ai/detail/:recordingId" component={DetailRecordingVipPage}></Route>}
                            {true && <Route path="/admin/recording-ai" component={RecordingVipPage}></Route>}
                            {isActive && <Route path="/admin/user-special-gift" component={UserSpecialGiftPage}></Route>}
                            {isActive && <Route path="/admin/categories" component={YoutubeSong}></Route>}
                            {isActive && <Route path="/admin/theVoiceExamnerPage" component={TheVoiceExamnerPage}></Route>}
                            {isActive && <Route path="/admin/item3D" component={Item3DPage}></Route>}
                            {isActive && <Route path="/admin/livestream" component={LivestreamPage}></Route>}
                            {isActive && <Route path="/admin/recording-vip-users" component={RecordingVipUsers}></Route>}
                            {isActive && <Route path="/admin/content-event" component={ContentEventPage}></Route>}
                            {isActive && <Route path="/admin/feature-request" component={FeatureRequestPage}></Route>}
                            {isActive && <Route path="/admin/user-post" component={UserPostPage}></Route>}
                            {isActive && <Route path="/admin/hashtag" component={HashtagPage}></Route>}
                            {isActive && <Route path="/admin/real-gift-manager" component={RealGiftPage}></Route>}
                            {isActive && <Route path="/admin/contests" component={CompetitionPage}></Route>}
                            {isActive && <Route path="/admin/banner-sell-mic" component={BannerSellMicPage}></Route>}
                            {isActive && <Route path="/admin/promo-banner-sell-mic" component={PromoBannerSellMicPage}></Route>}
                            {isActive && user.role === "SELLER" && <Route path="/admin/seller-manager" component={SellerPage} />}
                            {isActive && user.role === "MANAGER" && <Route path="/admin/seller-manager" component={ManagerSellerPage} />}
                            {isActive && <Route path="/admin/new-device-every-day/:id" component={ManagerNewDevice} />}
                            {isActive && <Route path="/admin/pin-mv-outstand" component={ManagerHotMv} />}
                            {isActive && <Route path="/admin/dead-account-manager" component={ManagerDeadAccount} />}
                            {isActive && <Route path="/admin/list-recordings/:uid" component={ListRecordingOfAccount} />}
                            {isActive && <Route path="/admin/midi-recording/:id" component={MidiRecordingDetail} />}
                            {isActive && <Route path="/admin/botmanager" component={BOTManagerPage}></Route>}
                            {isActive && <Route path="/admin/botfollow/:id" component={BOTFollowing}></Route>}
                            {isActive && <Route path="/admin/bot-interact-manager" component={BOTInteractManagerPage}></Route>}
                            {isActive && <Route path="/admin/top-beat-recording" component={TopBeatPage} />}
                            {isActive && <Route path="/admin/list-chat" component={ListChatSupportUser}></Route>}
                            {isActive && <Route path="/admin/idolmanager" component={IdolManagerPage}></Route>}
                            {isActive && <Route path="/admin/AvatarChangePage" component={AvatarChangePage}></Route>}
                            {isActive && <Route path="/admin/daily-task" component={DailyTaskPage}></Route>}
                            {isActive && <Route path="/admin/midi-score" component={MidiRecordingPage}></Route>}
                            {isActive && <Route path="/admin/top_video_recording" component={TopVideoRecordingPage}></Route>}
                            {isActive && <Route path="/admin/surveyNPS" component={SurveyNPSPage}></Route>}

                            {true && <Route path="/admin/manager-page" component={AdminPage}></Route>}
                            {true && <Route path="/admin/recheck-apple" component={RecheckApplePage}></Route>}
                            {true && <Route path="/admin/youtube-midi/add" component={AddYoutubeMidiPage}></Route>}
                            {true && <Route path="/admin/youtube-midi/detail/:youtubeId" component={DetailYoutubeMidiPage}></Route>}
                            {true && <Route path="/admin/youtube-midi" component={YoutubeMidiPage}></Route>}
                            {isActive && <Route path="/admin/lucky-dice-manager" component={GameBettingHistoryManagerPage}></Route>}
                            {isActive && <Route path="/admin/gift-level-manager" component={GiftLevelManagerPage}></Route>}
                            {isActive && <Route path="/admin/client-log" component={ClientLogPage}></Route>}
                            {isActive && <Route path="/admin/client-log-detail/:id" component={ClientLogDetailPage}></Route>}
                            {isActive && <Route path="/admin/dice-game-info-history" component={DiceGameInfoHistory}></Route>}
                            {isActive && <Route path="/admin/virtual-background" component={VirtualBackgroundPage}></Route>}
                            {isActive && <Route path="/admin/smartlook-manager" component={SmartLookManager}></Route>}
                            {isActive && <Route path="/admin/live-room-info" component={LiveRoomInfo}></Route>}
                            {isActive && <Route path="/admin/partner" component={PartnerManagerPage}></Route>}
                            {isActive && <Route path="/admin/icoin-detail/:uid" component={PartnerIcoinDetail}></Route>}
                            {isActive && <Route path="/admin/winrate-fish-hunter" component={WinRateFishHunterPage}></Route>}
                            <Route component={NotFoundPage}></Route>
                        </Switch>
                        <Footer></Footer>
                        {user != null ?
                            <>
                                <UnsubscribeOkaraDialog></UnsubscribeOkaraDialog>
                                <SubscribeOkaraDialog></SubscribeOkaraDialog>
                                <ConfirmSubscribeOkaraDialog></ConfirmSubscribeOkaraDialog>
                            </> 
                            :
                            <>
                                <PhoneVetificationDialog></PhoneVetificationDialog>
                                <ConfirmPhoneVetificationDialog></ConfirmPhoneVetificationDialog>
                                <LoginDialog></LoginDialog>
                                <RegisterPhoneDialog></RegisterPhoneDialog>
                            </>
                        }


                    </ScrollToTop>
                    {(detectApp("lo.okara") || detectApp("localhost") || detectApp("my.okara")) && (
                    <SingButton></SingButton>
                )}
                </Router>
                {/* </FacebookProvider> */}
                
                
            </MyContextProvider>
        )
    }
}
export default withTranslation()(App)

App.propTypes = {

};

