import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import MyContext from '../common/MyContext'
import { withTranslation } from 'react-i18next';
import { setCookie } from '../common/cookie.js';
import { detectApp } from '../common/utils.js';
import bindModel from '../common/bindModel.js';
import { toast } from 'react-toastify';
import AWS from 'aws-sdk';
import $ from 'jquery';

class RegisterPhoneDialog extends Component {
    constructor(props) {
        super(props);


        this.state = {
            name: "",
            profileImageLink: null,
            loading: false
        }
        this.uploadBeatToB2 = this.uploadBeatToB2.bind(this);
        this.registerPhone = this.registerPhone.bind(this);

    }


    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }


    uploadBeatToB2(event) {
        this.setState({
            loading: true
        });


        var target = event.target;

        var extension = target.files[0].name.split('.').pop().toLowerCase();
        // var credentialsBackblazeb2 = new AWS.Credentials("0023003e6144cac0000000004", "K002AE3odq+2F6w/D5Q8WUuNs8T84ps");
        var credentialsBackblazeb2 = new AWS.Credentials("0023003e6144cac0000000002", "K002nj/j9xWKFIZ/X8nEqZFQfchNPI0");

        var epBackblazeb2 = new AWS.Endpoint("s3.us-west-002.backblazeb2.com");
        var s3Backblazeb2 = new AWS.S3({
            endpoint: epBackblazeb2,
            credentials: credentialsBackblazeb2,
            region: "us-west-2",
        });



        // Create a bucket and upload something into it
        var bucketName = "ikara-data/okara/avatar";


        var contentType = "image/jpeg";
        if (extension == "png") contentType = "image/png";

        var keyName = this.guid() + "." + extension;

        var fileReader = new FileReader();


        fileReader.onload = function () {
            var data = fileReader.result; // data <-- in this var you have the file data in Base64 format
            console.log('https://s3.us-west-002.backblazeb2.com/' + bucketName + '/' + keyName);

            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', 'ikara-data');
            fd.append('objectKey', keyName);
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    this.setState({
                        loading: false,
                        profileImageLink: 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName
                    });
                    console.log(data);
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);
    }

    registerPhone() {
        this.setState({
            loading: true
        })

        const { userTemp } = this.context;

        const json = {
            phoneNumber: userTemp.phoneNumber,
            profileImageLink: this.state.profileImageLink,
            name: this.state.name,
            domain: window.location.hostname
        }

        axios.post(global.config.apiDomain + '/rest/okara/registerPhone', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false
            });
            if (res.data.status == "OK") {
                this.context.updateUser(res.data.user);
                window.$("#modalRegisterPhone").modal("hide");

                window.localStorage.setItem("login", true);
				window.localStorage.setItem("userId", res.data.user.facebookId);
				window.localStorage.setItem("profileImageLink", res.data.user.profileImageLink);
				window.localStorage.setItem("name", res.data.user.name);

				setCookie("login", "true", 1);
				setCookie("userId", res.data.user.facebookId, 1);
				setCookie("profileImageLink", res.data.user.profileImageLink, 1);
				setCookie("name", res.data.user.name, 1);
            } else {
                toast.warning(res.data.message);
            }
        });
    }

    render() {
        const model = bindModel(this);
        let { phone, profileImageLink } = this.state;
        const { t } = this.props;
        return (
            <div className="modal fade" data-backdrop="static" id="modalRegisterPhone" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">{t("register_phone_dialog.update_info", "Cập nhật thông tin")}</h5>
                            {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button> */}
                        </div>
                        <div className="modal-body">
                            <div className='text-center'>
                                {profileImageLink ? (
                                    <img className="rounded-circle avatar-profile" src={profileImageLink} />
                                ) : (
                                    <img className="rounded-circle avatar-profile" src={require('../assets/images/facebook_user.jpg')} />
                                )}
                                <br />
                                <div className="input-group mb-3 px-5">
                                    <div className="custom-file">
                                        <input
                                            onChange={this.uploadBeatToB2}
                                            type="file"
                                            className="custom-file-input"
                                            id="inputGroupFile01" />
                                        <label className="custom-file-label" htmlFor="inputGroupFile01">{t("register_phone_dialog.input_avatar", "Chọn ảnh đại diện")}</label>
                                    </div>
                                </div>
                            </div>
                            <div className='px-5'>
                                <input {...model('name')} type={"text"} placeholder={t("register_phone_dialog.input_name", "Nhập tên của bạn")} className='form-control' />
                            </div>
                            <div className='text-center mt-3'>
                                {this.state.loading ?
                                    (
                                        <button className="btn btn-red w-50" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            {t("register_phone_dialog.confirm", "Lưu thông tin")}...
                                        </button>
                                    ) :
                                    (
                                        <button type="button" onClick={this.registerPhone} className="btn btn-red w-50">{t("register_phone_dialog.confirm", "Lưu thông tin")}</button>
                                    )}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

RegisterPhoneDialog.contextType = MyContext;
export default withTranslation()(RegisterPhoneDialog)