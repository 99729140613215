import React, { Component } from 'react';
import axios from 'axios';
import '../../../assets/css/newDevice.css';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Firebase from '../../../components/Firebase'
import lodash from 'lodash';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import PaginatedTable from './PaginatedTable';

export default class ManagerNewDevice extends Component {
    constructor(props) {
        super(props);
        const { id } = this.props.match.params;
        this.state = {
            originalNewDevices: [],
            newDevices: [],
            selectedDate: '',
            selectedEndDate: '',
            isUserLoging: '',
            languageValue: '',
            paidUser: '',
            replyAdmin: '',
            pageSize: 30,
            lastLogin: '',
            platformValue: '',
            selectedRow: null,
            isLoading: false,
            load: id,
            addTimeSort: "DESC",
            setSmartLookSort: "ASC",
            haveSessionSort: "ASC"
        };

        this.getAllCustomerOfDevice = this.getAllCustomerOfDevice.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSelectDate = this.handleSelectDate.bind(this);
        this.handleDateEndChange = this.handleDateEndChange.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
        // this.handlLastLoginChange = this.handleLastLoginChange(this)
        this.handleSearch = this.handleSearch.bind(this);
        this.handleListAccountClick = this.handleListAccountClick.bind(this);
        this.orderByAddTime = this.orderByAddTime.bind(this);
        this.exportExcel = this.exportExcel.bind(this);

    }

    componentDidMount() {
        const day = window.localStorage.getItem("date");
        const endday = window.localStorage.getItem("enddate");
        console.log(day + " ngày " + endday)
        if (this.state.load == 1) {
            this.setState({ selectedDate: day, selectedEndDate: endday }, () => {
                this.getAllCustomerOfDevice();
            });
        } else {
            const today = new Date().toISOString().split('T')[0];
            this.setState({ selectedDate: today, selectedEndDate: today }, () => {
                this.getAllCustomerOfDevice();
            });
        }
    }


    exportExcel() {
        let { newDevices } = this.state;

        // Chỉ định các trường bạn muốn xuất
        const fieldsToExport = ["deviceId", "uid", "name", "addTime", "addTime_date", "addTime_time", "language", "platform", "model", "paidUser", "lastLogin", "lastLogin_date", "lastLogin_time", "setSmartLook", "haveSessionSmartLook", "linkSessionSmartLook"];
    
        // Lọc các trường từ dữ liệu
        const filteredDevices = newDevices.map(device => {
            const filteredDevice = {};
            fieldsToExport.forEach(field => {
                if(field == "addTime" || field == "lastLogin") {
                    filteredDevice[field] = new Date(device[field]).toLocaleString();
                } else if (field === "addTime_date") {
                    const date = new Date(device.addTime);
                    filteredDevice[field] = date.toLocaleDateString();
                } else if (field === "addTime_time") {
                    const date = new Date(device.addTime);
                    filteredDevice[field] = date.toLocaleTimeString();
                } else if (field === "lastLogin_date" && device.uid && device.uid !== '') {
                    const date = new Date(device.lastLogin);
                    filteredDevice[field] = date.toLocaleDateString();
                } else if (field === "lastLogin_time" && device.uid && device.uid !== '') {
                    const date = new Date(device.lastLogin);
                    filteredDevice[field] = date.toLocaleTimeString();
                } else {
                    filteredDevice[field] = device[field];
                }
            });
            return filteredDevice;
        });
    
        // Tạo bảng tính Excel từ dữ liệu đã lọc
        const worksheet = XLSX.utils.json_to_sheet(filteredDevices);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
    
        const file = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(file, "smart_look_data.xlsx");
    }


    orderByAddTime(type) {
        let { newDevices, addTimeSort, setSmartLookSort, haveSessionSort } = this.state

        if(type == "ADDTIME") {
            if (addTimeSort === "ASC") {
                newDevices = lodash.orderBy(newDevices, "addTime", 'desc');
                addTimeSort = "DESC";
            } else {
                newDevices = lodash.orderBy(newDevices, "addTime", 'asc');
                addTimeSort = "ASC";
            }
        } else if(type == "SETSMARTLOOK") {
            if (setSmartLookSort === "ASC") {
                newDevices = lodash.orderBy(newDevices,  ["setSmartLook", "haveSessionSmartLook"], ['desc', 'asc']);
                setSmartLookSort = "DESC";
            } else {
                newDevices = lodash.orderBy(newDevices,  ["setSmartLook", "haveSessionSmartLook"], ['asc', 'desc']);
                setSmartLookSort = "ASC";
            }
        } else if(type == "HAVESESSION") {
            if (haveSessionSort === "ASC") {
                newDevices = lodash.orderBy(newDevices, "haveSessionSmartLook", 'desc');
                haveSessionSort = "DESC";
            } else {
                newDevices = lodash.orderBy(newDevices, "haveSessionSmartLook", 'asc');
                haveSessionSort = "ASC";
            }
        }



        this.setState({
            addTimeSort: addTimeSort,
            setSmartLookSort: setSmartLookSort,
            haveSessionSort: haveSessionSort,
            newDevices: newDevices
        });
    }

    async getAllCustomerOfDevice() {
        this.setState({ isLoading: true });
        const { selectedDate, selectedEndDate } = this.state;
        if (selectedDate <= selectedEndDate) {
            const res = await axios.post(global.config.apiDomain + '/rest/new-device-and-user/new-device', { selectedDate: selectedDate, selectedEndDate: selectedEndDate }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const models = res.data.models || [];

            this.setState({
                originalNewDevices: models,
                newDevices: models,
                isLoading: false
            });
        } else {
            toast.error("Ngày kết thúc phải lớn hơn hoặc bằng ngày bắt đầu !")
            this.setState({ isLoading: false });
        }

    }

    handleDateChange(event) {
        this.setState({ selectedDate: event.target.value });
    }
    handleLastLoginChange = (event) => {
        let selectedDate = event.target.value
        if (selectedDate) {
            const dateObj = new Date(selectedDate);
            const day = String(dateObj.getDate()).padStart(2, '0');
            const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // tháng bắt đầu từ 0
            const year = dateObj.getFullYear();
            this.setState({ lastLogin: `${day}/${month}/${year}` });
        } else {
            this.setState({ lastLogin: '' });
        }
    }
    handleDateEndChange(event) {
        this.setState({ selectedEndDate: event.target.value });
    }

    handleSelectDate() {
        this.getAllCustomerOfDevice();
        window.localStorage.setItem("date", this.state.selectedDate)
        window.localStorage.setItem("enddate", this.state.selectedEndDate)
    }

    handleInputChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    handleSearch() {
        const { isUserLoging, languageValue, platformValue, originalNewDevices, paidUser, replyAdmin, lastLogin } = this.state;
        console.log(lastLogin);


        const filteredDevices = originalNewDevices.filter(device => {
            console.log(device.lastLogin);

            const isUserLogingMatch = (isUserLoging === "loging" && device.uid && device.uid !== "") ||
                (isUserLoging === "isLoging" && (!device.uid || device.uid === "")) ||
                (!isUserLoging || isUserLoging === "");
            const languageMatch = !languageValue || (device.language && device.language.toLowerCase().includes(languageValue.toLowerCase()));
            const platformMatch = !platformValue || (device.platform && device.platform.toLowerCase().includes(platformValue.toLowerCase()));
            var stringVariable = device.paidUser ? 'true' : 'false';
            const paidUserMatch = !paidUser || (stringVariable == paidUser);
            var stringVariable1 = device.replyAdmin ? 'true' : 'false';
            const replyAdminMatch = !replyAdmin || (stringVariable1 == replyAdmin);
            const lastLoginMatch = !lastLogin || (device.lastLogin && device.lastLogin.includes(lastLogin));
            return isUserLogingMatch && languageMatch && platformMatch && paidUserMatch && replyAdminMatch && lastLoginMatch;
        });
        this.setState({
            newDevices: filteredDevices
        });
    }

    handleListAccountClick(index) {
        this.setState(prevState => ({
            selectedRow: prevState.selectedRow === index ? null : index
        }));
    }
    formatDate(dateString) {
        const date = new Date(dateString);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
        return `${day}/${month}/${year}, ${formattedHours}:${minutes}:${seconds} ${ampm}`;
    }

    render() {
        const { newDevices, isUserLoging, languageValue, platformValue, isLoading, paidUser, replyAdmin, pageSize } = this.state;
        return (
            <>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">New Devices</h1>
                        <div className="search-container" style={{ marginBottom: "30px" }}>
                            <input className='mr-2' type="date" onChange={this.handleDateChange} value={this.state.selectedDate}></input>
                            Đến ngày
                            <input className='ml-2' type="date" onChange={this.handleDateEndChange} value={this.state.selectedEndDate}></input>
                            <button style={{ minWidth: '90px' }} onClick={this.handleSelectDate}>Chọn ngày</button>
                        </div>
                        <div className="search-container" style={{ marginBottom: "10px" }}>

                            <select name="isUserLoging" value={isUserLoging} onChange={this.handleInputChange}>
                                <option value="">Check Device</option>
                                <option value="loging">Đã đăng nhập</option>
                                <option value="isLoging">Chưa đăng nhập</option>
                            </select>
                            <select name="languageValue" value={languageValue} onChange={this.handleInputChange}>
                                <option value="">App</option>
                                <option value="vi">iKara</option>
                                <option value="en.yokara">Yokara</option>
                            </select>
                            <select name="platformValue" value={platformValue} onChange={this.handleInputChange}>
                                <option value="">Platform</option>
                                <option value="IOS">IOS</option>
                                <option value="ANDROID">ANDROID</option>
                            </select>
                            <select name="paidUser" value={paidUser} onChange={this.handleInputChange}>
                                <option value="">Paid User</option>
                                <option value="true">Đã chi tiêu</option>
                                <option value="false">Chưa chi tiêu</option>
                            </select>
                            <select name="replyAdmin" value={replyAdmin} onChange={this.handleInputChange}>
                                <option value="">Reply Admin</option>
                                <option value="true">Đã trả lời</option>
                                <option value="false">Chưa trả lời</option>
                            </select>
                            <select name="pageSize" value={pageSize} onChange={this.handleInputChange}>
                                <option value={30}>Page Size</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                            </select>
                            {/* <label className='m-0 mr-1' for="lastLogin">Last Login: </label>
                            <input type="date" onChange={this.handleLastLoginChange}></input> */}
                            <button style={{ minWidth: '80px' }} onClick={this.handleSearch}>Tìm kiếm</button>
                            <button style={{ minWidth: '80px' }} onClick={this.exportExcel}>Export</button>
                            <div style={{ minWidth: '80px' }} className="quantity">Số lượng: {newDevices.length}</div>
                        </div>
                        {isLoading ? (
                            <div className="spinner-container">
                                <div className="spinner"></div>
                            </div>
                        ) : newDevices.length > 0 ? (
                            // <table className="table table-hover">
                            //     <thead>
                            //         <tr className="text-center">
                            //             <th className="align-middle" scope="col">No.</th>
                            //             <th className="align-middle" scope="col">
                            //                 AddTime
                            //             <button
                            //                 className="btn btn-outline-secondary ml-1 p-1"
                            //                 onClick={() => this.orderByAddTime("ADDTIME")}>
                            //                 <i className="fas fa-sort" />
                            //             </button>
                            //             </th>
                            //             <th className="align-middle" scope="col">DeviceId</th>
                            //             <th className="align-middle" scope="col">App</th>
                            //             <th className="align-middle" scope="col">Platform</th>
                            //             <th className="align-middle" scope="col">Uid</th>
                            //             <th className="align-middle" scope="col">Name</th>
                            //             <th className="align-middle" scope="col">PaidUser</th>
                            //             <th className="align-middle" scope="col">LastLogin</th>
                            //             <th className="align-middle" scope="col" style={{width: "150px"}}>
                            //                 HaveSession
                            //             <button
                            //                 className="btn btn-outline-secondary ml-1 p-1"
                            //                 onClick={() => this.orderByAddTime("HAVESESSION")}>
                            //                 <i className="fas fa-sort" />
                            //             </button>
                            //             </th>
                            //             <th className="align-middle" scope="col" style={{width: "150px"}}>SmartLook
                            //             <button
                            //                 className="btn btn-outline-secondary ml-1 p-1"
                            //                 onClick={() => this.orderByAddTime("SETSMARTLOOK")}>
                            //                 <i className="fas fa-sort" />
                            //             </button>
                            //             </th>
                            //             <th className="align-middle" scope="col">Action</th>
                            //         </tr>
                            //     </thead>
                            //     <tbody>
                            //         {newDevices.map((item, index) => {
                            //             let addTime = new Date(item.addTime);
                            //             if(item.setSmartLook == null) {
                            //                 item.setSmartLook = false;
                            //             }

                            //             if(item.haveSessionSmartLook == null) {
                            //                 item.haveSessionSmartLook = false;
                            //             }
                            //             return (
                                            
                            //                 <tr key={item.deviceId}>
                            //                     <td className="align-middle text-center" scope="col">{index + 1}</td>
                            //                     <td className="align-middle text-center" scope="col">{addTime.toLocaleString()}</td>
                            //                     <td className="align-middle text-center" scope="col">{item.deviceId}</td>
                            //                     <td className="align-middle text-center" scope="col">{item.language}</td>
                            //                     <td className="align-middle text-center" scope="col">{item.platform}</td>
                            //                     <td className="align-middle text-center" scope="col">{item.uid}</td>
                            //                     <td className="align-middle text-center" scope="col">{item.name}</td>
                            //                     {item.paidUser == false ?
                            //                         <td className="align-middle text-center text-danger" scope="col"><strong>FALSE</strong></td> :
                            //                         <td className="align-middle text-center text-success" scope="col"><strong>TRUE</strong></td>}
                            //                     {item.uid && item.uid != '' ?
                            //                         <td scope="col" className="align-middle text-center">{item.lastLogin}</td>
                            //                         :
                            //                         <td scope="col" className="align-middle text-center">Chưa đăng nhập</td>}
                            //                     {item.haveSessionSmartLook
                            //                         ? (
                            //                             <td className="align-middle text-center text-success fw-bold" scope="col">
                            //                                 <a href={item.linkSessionSmartLook} target="_blank" rel="noopener noreferrer">
                            //                                 <strong>TRUE</strong>
                            //                                 </a>
                            //                             </td>
                            //                         )
                            //                         : (
                            //                             <td className="align-middle text-center text-danger" scope="col"><strong>FALSE</strong></td>
                            //                         )
                            //                     }
                            //                     {item.setSmartLook ? (
                            //                             <td className="align-middle text-center text-success" scope="col">
                            //                                 <a href={item.linkSessionSmartLook} target="_blank" rel="noopener noreferrer">
                            //                                 <strong>ENABLE</strong>
                            //                                 </a>
                            //                             </td>
                            //                     ) : (
                            //                         item.linkSessionSmartLook ? 
                            //                         <td className="align-middle text-center text-primary" scope="col">
                            //                             <a href={item.linkSessionSmartLook} target="_blank" rel="noopener noreferrer">
                            //                                 <strong>DISABLE</strong>
                            //                             </a>
                            //                         </td> :
                            //                         <td className="align-middle text-center text-danger" scope="col"><strong>DISABLE</strong></td>
                            //                     )}
                            //                     <td className="align-middle text-center" scope="col">
                            //                         {item.uid && item.uid != '' ?
                            //                             <Link to={`/admin/support-new/${item.uid}`}>
                            //                                 <button type="button" className="btn btn-success">Nhắn Tin</button>
                            //                             </Link> : <Link to={`/admin/support-new/${item.deviceId}`}>
                            //                                 <button type="button" className="btn btn-success">Nhắn Tin</button>
                            //                             </Link>}
                            //                     </td>
                            //                 </tr>
                            //             )
                            //         })}
                            //     </tbody>
                            // </table>
                            <PaginatedTable newDevices={newDevices} pageSize={pageSize}></PaginatedTable>
                        ) : (
                            <div className="text-center">
                                <p>Không có thiết bị nào.</p>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}
